import { AuthSession, AuthToken } from "@looker/sdk-rtl";
import { NotImplementedError, UnauthenticatedError } from "../../errors";
const noOp = () => {
    // no operation
};
export class MadLookerSession extends AuthSession {
    /**
     * @param settings: Looker API settings.
     * @param transport: the actual transport layer.
     */
    constructor(settings, transport) {
        super(settings, transport);
        this.settings = settings;
        this.transport = transport;
    }
    /**
     * Begins the session - associating the raw token string and its expiration in an object that will track it relative to the current time.
     * @param accessToken: the token that is associated with the session.
     * @return: the auth token, in a Looker-provided convenience wrapper (that tracks ttl, etc.).
     */
    async start(accessToken, ttl) {
        if (this.ending) {
            await this.ending.catch(noOp);
            this.ending = undefined;
        }
        this.token = new AuthToken({ access_token: accessToken, expires_in: ttl });
        return this.token;
    }
    /** @deprecated: login is not used, as login is handled entirely by the SDK's auth module. */
    async login() {
        throw new NotImplementedError("login");
    }
    /**
     * Ends the session, removing the token and deleting it on Looker's side.
     * @return: true if the session is ended successfully. Else, false.
     */
    async end() {
        let succeeded = true;
        if (!this.ending) {
            this.ending = this.transport.request("DELETE", "/api/4.0/logout", null, null, this.authenticate.bind(this));
        }
        try {
            await this.ending;
        }
        catch {
            succeeded = false;
        }
        this.ending = undefined;
        this.token = undefined;
        return succeeded;
    }
    /** @deprecated: logout is not used. Use `end` if the session should be terminated. */
    async logout() {
        throw new NotImplementedError("logout");
    }
    /**
     * @return: the currently-set token.
     * @throws: `UnauthenticatedError` if the token is not set.
     */
    async getToken() {
        if (!this.token?.access_token) {
            throw new UnauthenticatedError();
        }
        return this.token.access_token;
    }
    /** @return: true if the session has valid auth info, that is active (i.e., not expired). Else, false. */
    isAuthenticated() {
        return !!this.token?.isActive() && !this.ending;
    }
    /**
     * Modifies the passed request props to be authenticated with this session's info.
     * @param props: some request's props.
     * @return: the modified set of request props.
     */
    async authenticate(props) {
        if (this.isAuthenticated()) {
            props.mode = "cors";
            // remove any credentials attribute that may have been set
            // because the BrowserTransport defaults to having `same-origin` for credentials
            delete props["credentials"];
            props.headers = {
                ...props.headers,
                Authorization: `token ${this.token?.access_token}`
            };
        }
        return props;
    }
}
