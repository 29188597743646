import { useCallback } from "react";
import { Validation } from "@madhive/mad-sdk";
import { useSingularMadHooksUtils } from "./singularUtils";
/**
 * Returns a function that will validate and update the singular with the provided data.
 * @param collection Collection the data belongs to
 * @param id The id of the data
 * @returns a Promise
 */
export const useChange = (collection, id) => {
    const { observer, handler } = useSingularMadHooksUtils(collection, id);
    const getValidations = (validate, key) => {
        if (validate instanceof Promise) {
            return new Promise((resolve) => {
                validate.then((validated) => {
                    if (typeof validated === "string") {
                        const validation = new Validation();
                        key && validation.set(key, validated);
                        return resolve(validation);
                    }
                    return resolve(validated);
                });
            });
        }
        if (typeof validate === "string") {
            const validation = new Validation();
            key && validation.set(key, validate);
            return Promise.resolve(validation);
        }
        return Promise.resolve(validate);
    };
    const validateFunction = useCallback((validatorKey, clearAll) => new Promise((resolve) => {
        const keys = typeof validatorKey === "string" ? [validatorKey] : validatorKey;
        const current = observer.value;
        const validationErrors = !clearAll && keys ? current.validationErrors || new Validation() : new Validation();
        observer.next({
            ...current,
            isValidating: true
        });
        if (keys) {
            keys.forEach((key) => validationErrors.delete(key));
        }
        // @ts-ignore
        const validate = handler.validate(current.pendingChanges, keys);
        getValidations(validate).then((validations) => {
            validations.forEach((value, key) => {
                validationErrors.set(key, value);
            });
            observer.next({
                ...current,
                validationErrors,
                isValidating: false
            });
            resolve(validationErrors);
        });
    }), [id, handler]);
    const changeFunction = useCallback((changes, shoudValidate = true) => {
        const current = observer.value;
        const validationErrors = current.validationErrors || new Validation();
        observer.next({
            ...current,
            isValidating: true
        });
        const validations = [];
        // Note: Spread operator avoided here because pendingChanges proxy is pointing to this deltas.
        Object.assign(current.deltas, changes);
        Object.keys(changes).forEach((key) => {
            const value = changes[key];
            // Remove delta if setting back to original value, but not if it was not originally defined because
            // we want to validate in case an empty state is not allowed.
            if (typeof current?.data === "object" &&
                key in current.data &&
                current.data?.[key] === value) {
                delete current.deltas[key];
                if (validationErrors?.has(key)) {
                    validationErrors.delete(key);
                }
                return;
            }
            if (shoudValidate) {
                validations.push(new Promise((resolve) => {
                    // @ts-ignore
                    const validate = handler.validate(current.pendingChanges, key, value);
                    const keyString = key.toString();
                    getValidations(validate, keyString).then((validation) => {
                        if (validation) {
                            const val = validation.get(keyString);
                            val && validationErrors.set(keyString, val);
                        }
                        else {
                            validationErrors.delete(key);
                        }
                        resolve();
                    });
                }));
            }
        });
        // As of right now we want to accept the change to deltas even if
        // validation happens to fail since we don't want to effect the users
        // input
        observer.next({
            ...current
        });
        Promise.all(validations).then(() => {
            observer.next({
                ...observer.value,
                validationErrors,
                isValidating: false
            });
        });
    }, [id, handler]);
    return { changeFunction, validateFunction };
};
