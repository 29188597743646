import { useMemo } from "react";

/**
 * @param selected: some set of IDs.
 * @param fixed: the set of IDs which should be fixed (i.e., always selected, not de-selectable).
 * @return: the proper array of selected IDs, including any fixed ones, along with a set of the IDs which are fixed (if any).
 */
export const useSelectedIds = (
  selected: string[] | string | undefined,
  fixed?: string[] | Set<string>
): {
  selectedIds: string[];
  fixed?: Set<string>;
} => {
  return useMemo(() => {
    let array: string[] = [];

    if (Array.isArray(selected)) {
      array = selected;
    }

    if (typeof selected === "string") {
      array = [selected];
    }

    let fixedSet: Set<string> | undefined;
    if (fixed) {
      fixedSet = Array.isArray(fixed) ? new Set(fixed) : fixed;
      array = [
        ...fixed,
        ...array.filter(selection => !fixedSet?.has(selection))
      ];
    }

    return {
      selectedIds: array,
      fixed: fixedSet
    };
  }, [selected, fixed]);
};
