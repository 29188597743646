import { CreativeLegacy, PublisherValidation } from "@madhive/mad-sdk";
import { css } from "@emotion/react";
import { madSDK } from "lib/sdk";
import { SortDirection } from "lib/utils/search";
import { buildMediaQuery } from "lib/utils/styles";

export enum CreativeLibraryColumn {
  ADVERTISER = "advertiser",
  APPROVED_BY = "approved-by",
  ASSET_URL = "asset-url",
  AUDIO_STANDARDS = "audio-standards",
  CAMPAIGNS = "campaigns",
  CATEGORY = "category",
  CLICK_THROUGH_URL = "click-through-url",
  CREATED_ON = "created-on",
  CREATIVE_ID = "creative-id",
  ID = "id",
  INSCAPE_DETECTED = "inscape-detected",
  INSCAPE_RESPONSE = "inscape-response",
  ISCI_CODE = "isci-code",
  LAST_UPDATED = "last-updated",
  LINE_ITEMS = "line-items",
  NAME = "name",
  SERVED_BY = "hosted-by",
  SOURCE = "source",
  THIRD_PARTY_TRACKING = "third-party-tracking",
  THIRD_PARTY_TRACKING_PIXELS = "third-party tracking pixels",
  THIRD_PARTY_TRACKING_PIXELS_PROVIDERS = "third-party-tracking-pixels-providers",
  UPDATED_BY = "updated-by",
  VIDEO_STANDARDS = "video-standards"
}

// PK: It's a damn shame enums can't be extended... yet (https://github.com/microsoft/TypeScript/issues/17592)
export enum CreativeLibraryTabId {
  VIDEO = "video",
  DISPLAY = "display",
  AUDIO = "audio"
}

export const BASIC_VALIDATION_PUBLISHER = "BASIC";

export const CREATIVE_COLUMN_ID = { id: CreativeLibraryColumn.ID, label: "ID" };

export type CreativeLibraryColumnSchema = { id: string; label: string };

/**
 *
 * @param currentTab: Current tab being displayed in the Creative Library (video, audio, or display)
 * @param dynamicColumns: An array of columns related to the creative specs
 * @returns An array of CreativeLibraryColumns based on the CreativeLibrary Tab. Defaults to Video Columns containing DEFAULT_VIDEO_SPEC_COLUMNS when no tab/dynamicColumns are provided
 */
export const getCreativeLibraryColumns = (
  currentTab: CreativeLibraryTabId = CreativeLibraryTabId.VIDEO,
  dynamicColumns: Array<{ id: string; label: string }> = []
): CreativeLibraryColumnSchema[] => {
  const publisherColumns: Array<{ id: string; label: string }> = [];

  const user = madSDK.getCurrentUser();

  if (user?.settings?.publisher_validations) {
    user.settings.publisher_validations.forEach(
      (validation: PublisherValidation) => {
        publisherColumns.push({
          id: validation.id,
          label: validation.name
        });
      }
    );
  }

  const STANDARD_COLUMNS = [
    CREATIVE_COLUMN_ID,
    { id: CreativeLibraryColumn.NAME, label: "Name" }
  ];

  // Display Tab
  if (currentTab === CreativeLibraryTabId.DISPLAY) {
    return [
      ...STANDARD_COLUMNS,
      {
        id: CreativeLibraryColumn.THIRD_PARTY_TRACKING_PIXELS,
        label: "Third-Party Tracking Pixels"
      },
      ...dynamicColumns,
      {
        id: CreativeLibraryColumn.ADVERTISER,
        label: "Advertiser"
      },
      { id: CreativeLibraryColumn.CLICK_THROUGH_URL, label: "Click-Through" },

      { id: CreativeLibraryColumn.CREATED_ON, label: "Uploaded On" },
      { id: CreativeLibraryColumn.LAST_UPDATED, label: "Last Updated" },
      { id: CreativeLibraryColumn.UPDATED_BY, label: "Updated By" }
    ];
  }

  // Audio Tab
  if (currentTab === CreativeLibraryTabId.AUDIO) {
    return [
      ...STANDARD_COLUMNS,
      { id: CreativeLibraryColumn.AUDIO_STANDARDS, label: "Audio Standards" },
      {
        id: CreativeLibraryColumn.THIRD_PARTY_TRACKING_PIXELS,
        label: "Third-Party Tracking Pixels"
      },
      {
        id: CreativeLibraryColumn.ADVERTISER,
        label: "Advertiser"
      },
      { id: CreativeLibraryColumn.CAMPAIGNS, label: "Campaigns" },
      { id: CreativeLibraryColumn.LINE_ITEMS, label: "Line Items" },
      ...dynamicColumns,
      { id: CreativeLibraryColumn.CREATED_ON, label: "Uploaded On" },
      { id: CreativeLibraryColumn.LAST_UPDATED, label: "Last Updated" },
      { id: CreativeLibraryColumn.UPDATED_BY, label: "Updated By" }
    ];
  }

  // Video Tab
  return [
    ...STANDARD_COLUMNS,
    {
      id: CreativeLibraryColumn.INSCAPE_RESPONSE,
      label: "Inscape Response"
    },
    {
      id: CreativeLibraryColumn.INSCAPE_DETECTED,
      label: "Inscape Detected"
    },
    {
      id: CreativeLibraryColumn.THIRD_PARTY_TRACKING_PIXELS,
      label: "Third-Party Tracking Pixels"
    },
    ...publisherColumns,
    { id: CreativeLibraryColumn.LAST_UPDATED, label: "Last Updated" },
    { id: CreativeLibraryColumn.CREATED_ON, label: "Uploaded On" },
    { id: CreativeLibraryColumn.UPDATED_BY, label: "Updated By" },
    { id: CreativeLibraryColumn.ISCI_CODE, label: "ISCI/AD-ID Code" },
    {
      id: CreativeLibraryColumn.ADVERTISER,
      label: "Advertiser"
    },
    { id: CreativeLibraryColumn.CATEGORY, label: "Category" },
    { id: CreativeLibraryColumn.CAMPAIGNS, label: "Campaigns" },
    { id: CreativeLibraryColumn.LINE_ITEMS, label: "Line Items" },

    {
      id: CreativeLibraryColumn.CLICK_THROUGH_URL,
      label: "Click-Through"
    },
    ...dynamicColumns,
    { id: CreativeLibraryColumn.SOURCE, label: "Source" },
    {
      id: CreativeLibraryColumn.ASSET_URL,
      label: "Asset URL"
    }
  ];
};

export const DURATION_SPEC = "DURATION";

export const CREATIVE_COLUMN_TO_SERVICE_COLUMN: Partial<
  Record<CreativeLibraryColumn, string>
> = {
  [CreativeLibraryColumn.NAME]: "name",
  [CreativeLibraryColumn.CAMPAIGNS]: "associated_campaigns",
  [CreativeLibraryColumn.LINE_ITEMS]: "associated_lineitems",
  [CreativeLibraryColumn.CREATED_ON]: "created_at",
  [CreativeLibraryColumn.LAST_UPDATED]: "updated_at",
  [CreativeLibraryColumn.UPDATED_BY]: "updated_by",
  [CreativeLibraryColumn.ISCI_CODE]: "isci_code",
  [CreativeLibraryColumn.ADVERTISER]: "parent_name"
};

export const SORT_DIRECTION_TO_SERVICE_SORT_DIRECTION = {
  [SortDirection.ASCENDING]: "asc",
  [SortDirection.DESCENDING]: "desc"
};

export const FAILED_DURATION_TOOLTIP_TEXT =
  "Did not meet the acceptable video durations: ≤ 15.10s, ≤ 30.10s, ≤ 60.10s";

export interface CreativeTableProps {
  selectedItems?: string[];
  onSelectItem?: (id: string) => void;
  availableCreatives?: CreativeLegacy[];
  isLoading?: boolean;
  columns: Array<{ id: string; label: string }>;
  enabledColumnIds: Set<CreativeLibraryColumn>;
  creatives: CreativeLegacy[];
  isBulkAssign?: boolean;
  initialSortedColumnIndex: number;
  onArchiveCreativeClick: (creative: CreativeLegacy) => void;
  onUnArchiveCreativeClick: (creative: CreativeLegacy) => void;
  creativeLibraryTabId: CreativeLibraryTabId;
  canManage: boolean;
}

export const creativeScreenClasses = {
  svgIcon: css``,
  activeFill: css`
    fill: var(--primary-color);
  `,
  passiveFill: css`
    fill: #afbaca;
  `,
  gridViewWrapper: css`
    display: grid;
    grid-template-columns: repeat(4; 1fr);
    grid-gap: var(--spacing-24);
    padding: var(--spacing-24);
  `,
  thumbNailWrapper: css`
    cursor: pointer;
    overflow: auto;
    position: relative;
    height: 200px;
  `,
  thumbNailImage: css``,
  thumbNailOverlay: css`
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    color: var(--white);
    letter-spacing: 0.01em;
    font-size: 14px;
  `,
  thumbNailHeader: css`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  `,
  thumbNailActionWrapper: css`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    flex-grow: 1;
    opacity: 0;
    transition: opacity 150ms ease-in;
    &:hover {
      opacity: 1;
    }
  `,
  thumbNailAction: css`
    width: 35px;
    height: 35px;
    border-radius: 38px;
    background: var(--semi-dark);
    margin: 0px 15px;
    &:hover {
      opacity: 0.75;
    }
  `,
  thumbNailFooterWrapper: css`
    display: flex;
    justify-content: space-between;
    user-select: none;
  `,
  selected: css`
    font-style: italic;
    color: var(--semi-dark);
    font-weight: 400;
    font-size: 14px;
    margin-left: 20px;
  `,
  noResultCard: css`
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  `,
  tableWrapper: css`
    background: #fff;
  `,
  smallMarginBottom: css`
    margin-bottom: 10px;
  `,
  categoryLabel: css`
    font-weight: bold;
    margin-bottom: 10px;
  `,

  categoryValue: css`
    color: var(--semi-dark);
    line-height: 21px;
    word-break: break-word;
    white-space: normal;
  `,
  sectionWrapper: css`
    margin-left: 10px;
  `
};

export const creativeLibraryRowClasses = {
  loadingCell: css``,
  disabledRow: css`
    opacity: 0.4;
  `,
  cellWithImage: css`
    display: flex;
    align-items: center;
    margin-left: 15px;
    ${buildMediaQuery.max("lg")} {
      flex-direction: column;
    }
  `,
  noLeftMargin: css`
    margin-left: 0px;
  `,
  clickThroughCell: css`
    word-break: break-all;
  `,
  clickThroughCellText: css`
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: 150px;
  `,
  thumbnail: css`
    border-radius: 5px;
    max-width: 100px;
    min-width: 100px;
    width: 100px;
    max-height: 60px;
    min-height: 60px;
    margin-right: var(--spacing-24);
  `,
  audioSvgBG: css`
    background-color: var(--gray-5);
  `,
  emptyState: css`
    & > div {
      transform: scale(0.5);
    }
  `,
  creativeNameText: css`
    word-break: break-all;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 200px;
    white-space: nowrap;
    ${buildMediaQuery.between("md", "xl")} {
      min-width: 150px;
    }
  `,
  creativeIdText: css`
    word-break: break-all;
    font-size: var(--font-size-12px);
    color: var(--semi-dark);
    white-space: nowrap;
    ${buildMediaQuery.between("md", "xl")} {
      min-width: 150px;
    }
  `,
  creativeIdColumn: css`
    ${buildMediaQuery.between("md", "xl")} {
      min-width: 150px;
    }
  `,
  nameColumn: css`
    width: 450px;
  `,
  durationCell: css``,
  linkText: css`
    font-size: 16px;
  `,
  danger: css`
    color: var(--error-light);
  `,
  assetUrl: css``,
  highlightGray: css`
    background-color: var(--gray-light);
  `,
  creativeClickthrough: css`
    cursor: pointer;
    &:hover {
      color: #106ba3;
      text-decoration: underline;
    }
  `,
  statusIconContainer: css`
    margin-right: 20px;
    margin-left: 10px;
    display: flex;
    align-items: center;
  `,
  nameCellContainer: css`
    display: flex;
    flex-direction: column;
    line-height: inherit;
    gap: 10px;
    & p {
      font-size: var(--font-size-12);
      line-height: inherit;
    }
  `,
  videoStandardsWrapper: css`
    display: flex;
    align-items: center;
  `,
  videoStandardsValue: css`
    display: inline-block;
    margin: 0px 12px;
    text-transform: capitalize;
  `,
  centeredVertical: css`
    display: flex;
    align-items: center;
  `,
  categoryContainer: css`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 15px;
    margin: 10px 0px;
    &:not(:last-child) {
      padding-bottom: 10px;
      border-bottom: 1px solid var(--line-light);
    }
  `,
  spaceBetween: css`
    justify-content: space-between;
  `,
  assetValidationLabel: css`
    font-weight: bold;
    &::after {
      content: ":";
    }
  `,
  svgHeight: css`
    height: 14px;
  `,
  specValue: css`
    min-width: 100px;
  `,
  errorText: css`
    color: var(--red);
  `,
  fileCol: css`
    min-width: 300px;
  `,
  list: css`
    list-style-type: none;
    text-align: center;
  `,
  creativeHeaderWrap: css`
    display: grid;
    grid-template-columns: 5px 5fr 1fr 1fr 5fr;
    padding: 14px 10px;
    align-items: center;
    background-color: var(--gray-light);
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  `,
  creativeHeaderWrapEdit: css`
    grid-template-columns: 5px 5fr 1fr 1fr 3fr 2fr 2fr;
  `,
  videoStandardsWarningTooltip: css`
    display: flex;
    flex-direction: column;
    align-items: center;
  `,
  tooltipContainer: css`
    max-width: 300px;
  `
};
