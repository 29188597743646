import { css } from "@emotion/react";
import { kitText } from "frontier/lib/kit/styles";
import { shared } from "../styles";

export const styles = {
  base: css`
    ${shared.inputBase}
    --input-focus-color: var(--color-splash-primary);
    --input-width: 5rem;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    outline: none;
    .bp4-input-group {
      gap: var(--spacing-8);
    }
    input {
      ${kitText.style.monospace}
      border-radius: 0;
      color: var(--color-primary);
      padding-left: 0 !important;
      padding-right: 0 !important;
      min-width: var(--input-width);
    }
    .date-input-icon {
      transition: none !important;
    }
  `,
  withTime: css`
    --input-width: 12rem;
  `,
  range: {
    base: css`
      width: calc(
        (var(--input-width) * 2) + (var(--spacing-22) * 2)
      ) !important;
      padding: 0 !important;
      overflow: hidden;
      .bp4-input-group {
        gap: 0rem;
      }
      input {
        text-align: center;
      }

      .start-date-input:focus-within,
      .end-date-input:focus-within {
        input:focus,
        .date-input-icon:not(:focus) {
          background-color: var(--color-background-tertiary);
        }
      }
      .date-range-divider {
        width: var(--spacing-32);
        height: var(--input-height);
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        z-index: 1;
      }
    `,
    withPrefix: css`
      .date-input-icon {
        padding-left: var(--spacing-8);
        border-top-right-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
      }
    `,
    withSuffix: css`
      .date-input-icon {
        padding-right: var(--spacing-8);
        border-top-left-radius: 0 !important;
        border-bottom-left-radius: 0 !important;
      }
    `
  },
  withPrefix: css`
    ${shared.prefixAndSuffix}
    .date-input-icon {
      width: 1rem !important;
    }
  `,
  withSuffix: css`
    ${shared.prefixAndSuffix}
    .date-input-icon {
      width: 1rem !important;
    }
  `,
  disabled: css`
    ${shared.disabled}
  `,
  withError: css`
    ${shared.withError}
  `
};
