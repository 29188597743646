import { CampaignStatus, isLiveStatus } from "../../../models/campaigns/instruction";
import { DSP_OPTIONS, lineItemToServiceCampaignLineItem, serviceCampaignLineItemToCampaignLineItem } from "../../../models/campaigns/lineitems";
import { ObservableHandler } from "../../handlers";
import { FilterTypes, parseFilterFields, parseIdFilter } from "../../handlers/filter";
import { isPage, isServicePage } from "../../handlers/page";
import { MintedKeyFailure, ObjType, ServiceStatus } from "../../../types";
import { ValidationError } from "../../../validation";
import axios from "axios";
import { Observable, lastValueFrom } from "rxjs";
import { toQueryParams, toFilterObjects } from "../utils";
import { LineItemAudits } from "./audits";
import { SummaryInjector } from "./summaryInjector";
import { validators } from "./validators";
import { BulkLineItemHandler } from "./bulk";
import { Templates } from "./templates";
import { LineItemCreativeFlights } from "./creativeFlights";
class LineItems extends ObservableHandler {
    constructor(sdk) {
        super(sdk, "lineitems", { atomize: true });
        this.getLineItemDSP = (lineItem) => {
            return axios
                .post(`${this.sdk.urls.baseAPIUrl}/generateTpaUrl`, JSON.stringify({
                lineItem: lineItem,
                org_ids: DSP_OPTIONS.map((o) => o.id)
            }), {
                headers: {
                    "Content-Type": "application/json"
                }
            })
                .then((res) => res.data.data.find((d) => d.org_id === lineItem.tpa_id));
        };
        this.validateUpdates = (data) => {
            const errors = [];
            const items = Array.isArray(data) ? data : [data];
            items.forEach((item) => {
                const result = this.validate(item);
                if (result.size > 0) {
                    errors.push(result);
                }
            });
            return errors;
        };
        this.validators = validators(sdk);
        this.audits = new LineItemAudits(sdk);
        this.bulk = new BulkLineItemHandler(sdk);
        this.templates = new Templates(sdk);
        this.summaryInjector = new SummaryInjector(sdk);
        this.creativeFlights = new LineItemCreativeFlights(sdk);
    }
    findItems(filters, sort) {
        return new Observable((subscriber) => {
            const idFilter = parseIdFilter(filters);
            const promise = idFilter.size === 1
                ? this.getLineItem(idFilter.values().next().value).then((lineItem) => [lineItem])
                : this.getLineItems(filters, sort);
            promise
                .then(async (results) => {
                subscriber.next(results);
                const summaries = parseFilterFields(filters.fields, ["pacing", "videoCompletionRate"]);
                if (summaries.length > 0) {
                    const isPaged = isPage(results);
                    this.summaryInjector.inject(isPaged ? results.data : results, summaries).subscribe({
                        next: (lineItems) => subscriber.next(isPaged ? { page: results.page, data: lineItems } : lineItems),
                        error: (error) => subscriber.error(error),
                        complete: () => subscriber.complete()
                    });
                }
                else {
                    subscriber.complete();
                }
            })
                .catch((error) => {
                subscriber.error(error);
            });
        });
    }
    getLineItem(id) {
        return this.cache.promise(id, () => axios
            .get(
        // When getting a single line item, we will always want to include archived.
        `${this.sdk.urls.baseAPIUrl}/lineitem/${id}?includeArchived=true`, {
            headers: {
                "Content-Type": "application/json"
            }
        })
            .then((res) => this.toLineItem(res.data.data).then((lineItem) => {
            if (lineItem.tpa) {
                return this.getLineItemDSP(res.data.data).then((dsp) => {
                    lineItem.dsp = dsp;
                    return lineItem;
                });
            }
            else {
                return lineItem;
            }
        })));
    }
    getLineItems(filters, sort) {
        const params = toQueryParams(filters, sort);
        const pageSize = filters?.paging?.size || 10;
        const url = `${this.sdk.urls.baseAPIUrl}/lineitems${params}`;
        return this.cache.promise(url, () => axios
            .get(url, {
            headers: {
                "Content-Type": "application/json"
            }
        })
            .then(async ({ data: res }) => {
            const lineItems = res.data
                ? await Promise.all(res.data.map((serviceLineItem) => this.toLineItem(serviceLineItem)))
                : [];
            let results = lineItems;
            if (isServicePage(res)) {
                results = {
                    page: {
                        count: res.paging_info.count,
                        token: res.paging_info.token,
                        size: pageSize || lineItems.length
                    },
                    data: lineItems
                };
            }
            return results;
        }));
    }
    countByAudience(recipeId) {
        return axios
            .get(`${this.sdk.urls.baseAPIUrl}/lineitems?recipes=["${recipeId}"]`, {
            headers: {
                "Content-Type": "application/json"
            }
        })
            .then(({ data }) => data.paging_info?.count || 0);
    }
    saveItem(input, options) {
        return new Observable((subscriber) => {
            const { skipValidation } = options || {};
            if (!skipValidation) {
                const errors = this.validateUpdates(input);
                if (errors.length) {
                    subscriber.error(new ValidationError(errors));
                    return;
                }
            }
            const promise = Array.isArray(input) ? this.saveLineItems(input) : this.saveLineItem(input);
            promise
                .then((saved) => {
                subscriber.next(saved);
                this.cache.clear();
                subscriber.complete();
            })
                .catch((error) => {
                subscriber.error(error);
            });
        });
    }
    async saveLineItem(lineItem) {
        const id = lineItem.id || (await this.sdk.cryptography.mintKey(ObjType.INST));
        if (!id) {
            throw new MintedKeyFailure();
        }
        return axios
            .post(`${this.sdk.urls.baseAPIUrl}/lineitem/${id}`, this.toServiceLineItem({ ...lineItem, id: id }), {
            headers: {
                "Content-Type": "application/json"
            }
        })
            .then(() => {
            /* Backend tech debt: response from POST lineitem endpoint does
          not include a derived status for the line item. As this status is
          hugely important, we make another API request to ensure we have
          the most up to date data. */
            this.sdk.caches.clear("campaigns");
            this.cache.remove(id);
            return this.getLineItem(id);
        });
    }
    saveLineItems(lineItems) {
        const ids = [];
        const serviceReadyLineItems = [];
        for (const item of lineItems) {
            ids.push(item.id);
            serviceReadyLineItems.push(this.toBulkServiceLineItem(item));
        }
        return axios
            .patch(`${this.sdk.urls.baseAPIUrl}/bulkLineItem`, { data: serviceReadyLineItems }, {
            headers: {
                "Content-Type": "application/json"
            }
        })
            .then(async () => {
            this.sdk.caches.clear("campaigns");
            // a bulk response replies with a CSV diff
            // so we fetch all the items we saved to get their most up-to-date data
            const saved = await lastValueFrom(this.find({
                where: [{ field: "id", type: FilterTypes.IN, value: ids }]
            })).catch((error) => {
                // if we fail to find the new reps, ensure the cache is not left in a bad state
                this.cache.clear();
                throw error;
            });
            return isPage(saved) ? saved.data : saved;
        });
    }
    deleteItem(id) {
        return axios.delete(`${this.sdk.urls.baseAPIUrl}/lineitem/${id}`).then(() => {
            this.sdk.caches.clear("campaigns");
            return id;
        });
    }
    /**
     * Used to make a default line item.
     * @param defaults these will be used to override the standard defaults
     * @return Promise<LineItem> that will return the default line item object
     */
    make(defaults) {
        return new Promise((resolve, reject) => {
            this.sdk.cryptography
                .mintKey(ObjType.INST)
                .then((id) => {
                const lineItem = {
                    archivedCreatives: [],
                    attachedCreatives: [],
                    creativesCount: 0,
                    isLive() {
                        return isLiveStatus(this.status);
                    },
                    criticalStatuses: [],
                    endDate: new Date(),
                    externalId: "",
                    externalOrderManagementSystemId: "",
                    frequencies: [],
                    geoTargeting: {
                        include: {
                            country: "",
                            regions: [],
                            districts: [],
                            dmas: [],
                            postalCodes: []
                        },
                        exclude: {
                            country: "",
                            regions: [],
                            districts: [],
                            dmas: [],
                            postalCodes: []
                        }
                    },
                    id,
                    instructionStatus: ServiceStatus.DRAFT,
                    isEditable: true,
                    name: "",
                    parentName: "",
                    parent: this.sdk.getCurrentUser()?.primaryOrganizationId || "",
                    productId: "",
                    startAsap: false,
                    startDate: new Date(),
                    status: CampaignStatus.MISSING_CREATIVE,
                    version: 0,
                    publisherGroupId: "",
                    statusDetails: {
                        canBe: {
                            archived: true,
                            edited: true,
                            live: false,
                            paused: false,
                            unarchived: false
                        },
                        code: 100,
                        summary: CampaignStatus.MISSING_CREATIVE
                    },
                    ...defaults
                };
                resolve(lineItem);
            })
                .catch((error) => {
                reject(error);
            });
        });
    }
    toLineItem(serviceLineItem) {
        return serviceCampaignLineItemToCampaignLineItem(serviceLineItem, this.sdk.cryptography);
    }
    toServiceLineItem(lineItem) {
        return lineItemToServiceCampaignLineItem(lineItem);
    }
    toBulkServiceLineItem(lineItem) {
        const serviceLineItem = this.toServiceLineItem(lineItem);
        // bulkLineItem endpoint uses `dayparts_exclude` instead of `dayparts`.
        serviceLineItem.dayparts_exclude = serviceLineItem.dayparts;
        delete serviceLineItem.dayparts;
        // bulkLineItem endpoint uses `recipes` instead of `segments`.
        serviceLineItem.recipes = serviceLineItem.segments;
        delete serviceLineItem.segments;
        return serviceLineItem;
    }
    parseFilter(filters) {
        return toFilterObjects(filters);
    }
}
export default LineItems;
