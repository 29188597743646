import { css } from "@emotion/react";
import { kitText } from "frontier/lib/kit/styles";

export const styles = {
  wrapper: css`
    width: fit-content;
  `,
  fill: css`
    width: 100%;
  `,
  target: css`
    user-select: none;
    cursor: pointer;
    background: var(--color-background-primary);
    color: var(--color-tertiary);
    --icon-fill-color: var(--color-tertiary);
    border: 1px solid var(--color-border-primary);
    padding: var(--spacing-4);
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: var(--spacing-16);
    cursor: pointer;
    &:focus {
      outline: none;
      border-color: var(--color-splash-primary);
    }
  `,
  targetText: css`
    ${kitText.variant.body};
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    overflow: hidden;
    padding-right: var(--spacing-8);
    margin-left: var(--spacing-4);
  `,
  isDisabled: css`
    cursor: not-allowed;
    pointer-events: none;
    background: var(--color-background-tertiary);
    border-color: var(--color-border-secondary);
    * {
      color: var(--color-tertiary);
    }
  `,
  hasError: css`
    border-color: var(--color-danger);
    &:focus {
      border-color: var(--color-border-danger);
    }
  `,
  hasSelection: css`
    color: var(--color-primary);
  `,
  toggleIcon: css`
    transition: transform 0.2s ease-in-out;
  `,
  iconSizing: css`
    width: var(--spacing-24);
    height: var(--spacing-24);
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    padding: var(--spacing-4);
    box-sizing: border-box;
  `,
  clearButton: css`
    all: unset;
    --icon-fill-color: var(--color-tertiary);
  `
};
