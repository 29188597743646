import { CampaignAuditColumns, CampaignStatus } from "@madhive/mad-sdk";
import { CommonIconProps, IconNames } from "../kit/types";

export enum CampaignsQueryParams {
  /** The members of this enum will be used as the value of the query string in URLs. */
  TAB = "tab",
  VIEW_MODE = "mode"
}

export enum CampaignsTabIds {
  /** The members of this enum will be used as the value of the query string in URLs. */
  OVERVIEW = "overview",
  LINE_ITEMS = "line-items"
}

export const CAMPAIGN_DETAILS_VIEW_STATE_KEY =
  "CAMPAIGN_DETAILS_VIEW_STATE_KEY";

export const ADBOOK_MARKET_LIST = [
  "Bakersfield",
  "Baltimore",
  "Billings",
  "Boise",
  "Buffalo",
  "Butte",
  "Cincinnati",
  "Cleveland",
  "Colorado Springs",
  "Corpus Christi",
  "Denver",
  "Detroit",
  "Fort Myers",
  "Grand Rapids",
  "Great Falls",
  "Green Bay",
  "Helena",
  "Indianapolis",
  "Kansas City",
  "Lafayette",
  "Lansing",
  "Las Vegas",
  "Lexington",
  "Miami",
  "Milwaukee",
  "Missoula",
  "Nashville",
  "New York",
  "Norfolk",
  "Norfolk - CW",
  "Omaha",
  "Phoenix",
  "Phoenix - CW",
  "Richmond",
  "Salt Lake City",
  "San Diego",
  "San Luis Obispo",
  "Tallahassee",
  "Tampa",
  "Tucson",
  "Tulsa",
  "Waco",
  "West Palm"
];

export const UPDATED_LINE_ITEMS_KEY = "UPDATED_LINE_ITEMS";

export const CAMPAIGN_AUDIT_COLUMN_HEADERS: CampaignAuditColumns[] = [
  CampaignAuditColumns.NAME,
  CampaignAuditColumns.ID,
  CampaignAuditColumns.VERSION,
  CampaignAuditColumns.UPDATED_BY,
  CampaignAuditColumns.UPDATED,
  CampaignAuditColumns.START_DATE,
  CampaignAuditColumns.END_DATE,
  CampaignAuditColumns.LINE_ITEMS,
  CampaignAuditColumns.EXT_METAS,
  CampaignAuditColumns.EXTERNAL_ID,
  CampaignAuditColumns.STATUS,
  CampaignAuditColumns.ADVERTISER,
  CampaignAuditColumns.CATEGORIES
];

export const CAMPAIGN_AUDIT_COLUMN_IDS = {
  [CampaignAuditColumns.NAME]: "name",
  [CampaignAuditColumns.ID]: "id",
  [CampaignAuditColumns.VERSION]: "version",
  [CampaignAuditColumns.UPDATED_BY]: "updatedBy",
  [CampaignAuditColumns.UPDATED]: "lastUpdated",
  [CampaignAuditColumns.START_DATE]: "startDate",
  [CampaignAuditColumns.END_DATE]: "endDate",
  [CampaignAuditColumns.LINE_ITEMS]: "lineItems",
  [CampaignAuditColumns.EXT_METAS]: "meta",
  [CampaignAuditColumns.EXTERNAL_ID]: "externalId",
  [CampaignAuditColumns.STATUS]: "instructionStatus",
  [CampaignAuditColumns.ADVERTISER]: "advertiserId",
  [CampaignAuditColumns.CATEGORIES]: "categories"
};

export enum CAMPAIGN_MENU_ACTIONS {
  REFETCH = "Refetch",
  DOWNLOAD_AUDIT_REPORT = "Download Audit Report",
  ARCHIVE = "Archive",
  UNARCHIVE = "Unarchive"
}

export const STATUS_ICONS: Partial<
  Record<CampaignStatus, { name: IconNames } & Pick<CommonIconProps, "fill">>
> = {
  [CampaignStatus.CANT_SET_LIVE]: {
    name: "Block",
    fill: "var(--color-danger)"
  },
  [CampaignStatus.READY]: { name: "Pointer", fill: "var(--color-info)" },
  [CampaignStatus.CANCELLED]: {
    name: "Circled.X",
    fill: "var(--color-danger)"
  },
  [CampaignStatus.COMPLETED]: {
    name: "FlagWithCheck",
    fill: "var(--color-success)"
  },
  [CampaignStatus.PAUSED]: { name: "Paused", fill: "var(--color-warning)" },
  [CampaignStatus.LIVE]: {
    name: "HeartbeatMonitor",
    fill: "var(--color-success)"
  },
  [CampaignStatus.WAITING]: { name: "StopWatch", fill: "var(--color-info)" },
  [CampaignStatus.LIVE_WARNING]: {
    name: "Warning",
    fill: "var(--color-warning)"
  },
  [CampaignStatus.MISSING_WHITELIST]: {
    name: "Clipboard",
    fill: "var(--color-danger)"
  },
  [CampaignStatus.MISSING_CREATIVE]: {
    name: "VideoSlate",
    fill: "var(--color-danger)"
  },
  [CampaignStatus.MISSING_LINE_ITEM]: {
    name: "List",
    fill: "var(--color-danger)"
  },
  [CampaignStatus.MISSING_DETAILS]: {
    name: "Warning",
    fill: "var(--color-warning)"
  },
  [CampaignStatus.MISSING_GEO]: {
    name: "Geo",
    fill: "var(--color-warning)"
  },
  [CampaignStatus.MISSING_GOAL]: {
    name: "ReticleCrossed",
    fill: "var(--color-warning)"
  },
  [CampaignStatus.MISSING_PRODUCT]: {
    name: "BagWithMinus",
    fill: "var(--color-warning)"
  },
  [CampaignStatus.MISSING_FREQ_CAP]: {
    name: "AudioWaveform",
    fill: "var(--color-warning)"
  },
  [CampaignStatus.ARCHIVED]: {
    name: "Archive",
    fill: "var(--color-tertiary)"
  },
  [CampaignStatus.NO_ACTIVE_CREATIVE]: {
    name: "Warning",
    fill: "var(--color-warning)"
  }
};

export const TARGETING_LINK = `?tab=targeting`;
export const CREATIVES_LINK = `?tab=creatives`;

// Max number of items to display at once in the select lists to prevent hanging.
export const MAX_SELECT_LIST_OPTIONS = 500;
