import { FC, useEffect, useMemo } from "react";
import { v4 as uuid } from "uuid";
import { css } from "@emotion/react";
import { useFocusOnMount } from "frontier/lib/kit/hooks/useFocusOnMount";
import { InputNumberProps } from "../types";
import { getIconFromStringOrElement } from "../../Icons/utils";
import { styles } from "../styles";
import { useInputNumber } from "./hooks/useInputNumber";

/**
 * `<Input type="number" value={number | null} />` allows users to input a number.
 */
export const InputNumber: FC<InputNumberProps> = ({
  disabled = false,
  placeholder = "",
  focusOnMount = false,
  error = false,
  value,
  prefix,
  suffix,
  min,
  max,
  step,
  precision,
  onFocus,
  onChange,
  onBlur,
  onKeyDown,
  onKeyUp,
  onClick,
  onInput
}: InputNumberProps) => {
  const inputId = useMemo(() => `ws-${uuid()}`, []);

  const {
    setInputOnBlur,
    setOnInput,
    parseNumber,
    setRawValue,
    rawValue,
    inputRef
  } = useInputNumber({
    onBlur,
    onChange,
    precision,
    value,
    min,
    max
  });

  useEffect(() => {
    if (typeof value === "number") {
      setRawValue(parseNumber(value.toString())!);
    } else if (value === null) {
      setRawValue("");
    }
  }, [value]);

  const PrefixIcon = useMemo(() => {
    return getIconFromStringOrElement(prefix);
  }, [prefix]);

  const SuffixIcon = useMemo(() => {
    return getIconFromStringOrElement(suffix);
  }, [suffix]);

  useFocusOnMount(inputRef, focusOnMount);

  // if the step count is single digits, we want to show the increment/decrement arrows
  const stepCount = useMemo(() => {
    if (!max || !min || !step) {
      return Number.MAX_VALUE;
    }
    return Math.floor((max - min) / step);
  }, [step, max, min]);

  return (
    <div
      className="subKit-InputNumber"
      css={[
        styles.inputBase,
        !!prefix && styles.withPrefix,
        !!suffix && styles.withSuffix,
        !!disabled && styles.disabled,
        !!error && styles.withError
      ]}
    >
      {PrefixIcon && <span className="kit-input-prefix">{PrefixIcon}</span>}
      <input
        type="number"
        ref={inputRef}
        value={rawValue}
        disabled={disabled}
        id={inputId}
        min={min}
        max={max}
        step={step}
        placeholder={placeholder}
        onInput={e => {
          setOnInput(e);
          onInput?.(e);
        }}
        onKeyDown={onKeyDown}
        onKeyUp={onKeyUp}
        onFocus={onFocus}
        onBlur={setInputOnBlur}
        onClick={onClick}
        css={[
          stepCount >= 10 &&
            css`
              ::-webkit-outer-spin-button,
              ::-webkit-inner-spin-button {
                -webkit-appearance: none;
                margin: 0;
              }
            `
        ]}
      />
      {SuffixIcon && <span className="kit-input-suffix">{SuffixIcon}</span>}
    </div>
  );
};
