import React, { useEffect } from "react";
import { ClassNames } from "@emotion/react";
import { Popover, IPopoverSharedProps } from "@blueprintjs/core";
import { FlyoutProps } from "./types";

import { styles } from "./styles";

const Flyout: React.FC<FlyoutProps> = ({
  isOpen,
  target,
  offset = 0,
  children,
  boundary = "viewport",
  hasBackdrop = false,
  portalContainer,
  usePortal,
  position,
  lazy,
  dismissAncestors: captureDismiss,
  keepOpen,
  transitionMs = 300,
  enforceFocus = true,
  autoFocus = true,
  disabled = false,
  fill = false,
  onClose,
  onClosed,
  onOpened,
  onOpen,
  onInteraction,
  minimal = false,
  arrow,
  isStyled = false
}) => {
  const modifiers: IPopoverSharedProps["modifiers"] = {
    offset: { offset, enabled: typeof offset !== "undefined" },
    arrow: { enabled: arrow }
  };

  /**
   * need to account for scroll bars when hasBackdrop === true and document.body has overflow,
   * by adding padding to body
   */
  const addPadding = () => {
    if (hasBackdrop) {
      const body = document.body;

      if (
        body.scrollHeight > body.clientHeight ||
        body.scrollWidth > body.clientWidth
      ) {
        body.style.paddingRight = "15px";
      }
    }
  };

  /**
   * need remove padding on document.body if added onOpening
   */
  const removePadding = () => {
    const body = document.body;
    if (body.style.paddingRight) {
      body.style.removeProperty("padding-right");
    }
  };

  /**
   * Removes the the padding on the body when the component unmounts
   */
  useEffect(() => () => removePadding(), []);

  if (disabled) {
    return <>{children}</>;
  }

  return (
    <ClassNames>
      {({ css, cx }) => (
        <Popover
          isOpen={isOpen}
          portalContainer={portalContainer}
          usePortal={usePortal}
          position={position}
          lazy={lazy}
          captureDismiss={captureDismiss}
          transitionDuration={transitionMs}
          minimal={minimal}
          modifiers={modifiers}
          content={
            <div
              css={isStyled && styles.padding}
              onClick={e => !keepOpen && onClose?.(e)}
            >
              {children}
            </div>
          }
          target={target}
          boundary={boundary}
          hasBackdrop={hasBackdrop}
          onOpening={e => {
            addPadding();
            onOpen && onOpen(e);
          }}
          onClose={e => {
            removePadding();
            onClose && onClose(e);
          }}
          onOpened={onOpened}
          onClosed={onClosed}
          onInteraction={onInteraction}
          enforceFocus={enforceFocus}
          autoFocus={autoFocus}
          fill={fill}
          popoverClassName={cx(
            "kit-Flyout",
            css`
              ${!isStyled ? styles.wrapper : styles.base}
            `
          )}
        />
      )}
    </ClassNames>
  );
};

export default Flyout;
export * from "./types";
