import { css } from "@emotion/react";
import { kitText } from "frontier/lib/kit/styles";

export const styles = {
  base: css`
    box-shadow: var(--shadow-3);
    border-radius: 4px;
    border: 1px solid var(--color-border-primary);
    background: var(--color-background-primary);
    width: fit-content;
    display: flex;
    flex-direction: column;
    gap: var(--spacing-4);
    overflow: hidden;
    .kit-Input {
      flex-grow: 0;
      padding: var(--spacing-4);
      padding-bottom: 0;
      .subKit-InputText {
        border-radius: 2px;
      }
    }
  `,
  fill: css`
    width: 100%;
  `,
  scrollArea: css`
    display: flex;
    flex-direction: column;
    position: relative;
    gap: var(--spacing-4);
    overflow: auto;
    .subKit-DropdownList:not(:first-of-type) {
      border-top: 1px solid var(--color-border-secondary);
    }
  `,
  pinnedItems: css`
    width: 100%;
    background-color: var(--color-background-primary);
  `,
  searchableWrapper: css`
    --icon-fill: var(--color-splash-primary);
    padding: var(--spacing-4);
  `,
  selectedCount: css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: var(--spacing-12) var(--spacing-12) var(--spacing-8);
    ${kitText.variant.body}
  `,
  infoSection: css`
    --icon-fill-color: var(--color-secondary);
    width: 100%;
    padding: var(--spacing-8);
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: var(--spacing-8);
    ${kitText.variant.body}
  `,
  infoSectionError: css`
    --icon-fill-color: var(--color-danger);
  `
};
