import { Fragment, useMemo } from "react";
import { css } from "@emotion/react";
import { styles } from "./styles";
import { defaultLookupId } from "../utils";
import {
  TableColumnDef,
  TableColumnProps,
  TableProps,
  TableRowType
} from "./types";
import RowBorder from "./RowBorder";
import { withKitType } from "../HOC";
import { IdType } from "../types";
import TableRows from "./Rows";
import { useColumnDefs } from "./hooks";

export const Table = <
  Row extends TableRowType,
  Column extends TableColumnDef<Row>
>({
  columns,
  data: incomingData,
  rowMinHeight,
  children,
  lookupRowId = defaultLookupId
}: TableProps<Row, Column>) => {
  const columnDefs = useColumnDefs(columns, incomingData[0], children);

  const data = useMemo(() => {
    if (!incomingData) {
      return [];
    }

    // if columns are not provided, assume the first row is the column headers
    if (!columns?.length && Array.isArray(incomingData[0])) {
      return incomingData.slice(1);
    }

    return incomingData;
  }, [incomingData]);

  return (
    <div
      className="kit-Table"
      css={[
        styles.tableViewport,
        css`
          height: 100%;
          --table-row-min-height: ${rowMinHeight};
          --table-column-count: ${columnDefs.length};
        `
      ]}
    >
      <table
        css={[
          styles.table,
          css`
            grid-template-columns: repeat(
              var(--table-column-count),
              minmax(max-content, auto)
            );
          `
        ]}
      >
        <thead className="subKit-TableHeader" css={[styles.tableHeader]}>
          <tr css={[styles.tr]}>
            {columnDefs.map(column => (
              <Fragment key={String(column.key)}>
                <th css={[styles.th]}>{column.label}</th>
              </Fragment>
            ))}
          </tr>
          <RowBorder />
        </thead>

        <tbody css={styles.tableBody}>
          <TableRows
            data={data}
            columns={columnDefs as Column[]}
            lookupRowId={lookupRowId}
          >
            {children}
          </TableRows>
        </tbody>
      </table>
    </div>
  );
};

/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */

Table.Column = withKitType(
  "Table.Column",
  <Row extends TableRowType, ColumnKey extends IdType = IdType>(
    props: TableColumnProps<Row, ColumnKey>
  ) => <></>
);
