import { ViewId, User, UserAbility, UserOperationMode } from "@madhive/mad-sdk";

export const getUserPermissions = (
  viewId: ViewId,
  abilities: UserAbility[]
) => {
  const userAbilitiesForViewId =
    viewId === ViewId.FIRST_PARTY_DATA_ONBOARDING
      ? abilities.find(item => item.resource === "pii")
      : abilities.find(item => item.resource === viewId);

  const canView = !!userAbilitiesForViewId?.operations.includes(
    UserOperationMode.READ
  );

  const canManage = !!userAbilitiesForViewId?.operations.includes(
    UserOperationMode.UPDATE
  );

  const readOnly = canView && !canManage;

  return { canView, canManage, readOnly };
};

export const isUserAbleToViewAgainstViewId = (viewId: ViewId, user: User) => {
  if (user.isDev) {
    return true;
  }

  if (!user.abilities) {
    // TODO: The special check for 1P data onboarding is temporary and should be removed after we complete the transition from PII to 1P data onboarding everywhere else that it is used
    return (
      user.viewIds.includes(viewId) ||
      (viewId === ViewId.FIRST_PARTY_DATA_ONBOARDING &&
        user.viewIds.includes(ViewId.FIRST_PARTY_DATA_ONBOARDING))
    );
  }

  const { canManage, canView } = getUserPermissions(viewId, user.abilities);

  return canView || canManage;
};
