import {
  CampaignStatus,
  isActionRequiredStatus,
  findClickthroughFromEventsLegacy,
  fillInEmptyTrackingTypes,
  DEFAULT_EMPTY_TRACKING_PIXELS,
  DerivedLineItemStatus
} from "@madhive/mad-sdk";
import { getFormattedMetadataFields } from "frontier/lib/campaigns/utils";
import { CampaignId, ShallowLineItem } from "campaign-types";
import { RawInstructionStatus } from "features/ManageCampaigns/constants";
import { isCampaignDescendentEditable } from "features/ManageCampaigns/utils";
import { CampaignDescendantRaw } from "newnewyork";
import calculateUserShouldBeAbleToSetInstructionLive from "./calculateUserShouldBeAbleToSetInstructionLive";

const transformRawCampaignDescendant = (
  rawDescendant: CampaignDescendantRaw,
  campaignId: CampaignId,
  campaignName: string
): ShallowLineItem => {
  /* eslint-disable camelcase */
  const {
    start_date,
    start_asap,
    product_id,
    oms_id,
    name,
    parent,
    parent_name,
    end_date,
    creatives,
    booked,
    external_id,
    budget,
    dayparts,
    id,
    version,
    whitelist_id,
    owners,
    geo_include,
    geo_exclude,
    derived_statuses,
    media_type,
    segments,
    iab_category_rtb_ids
  } = rawDescendant;

  const startDate = new Date(start_date);
  const endDate = new Date(end_date);

  const creativesTransformed = creatives
    ? creatives.map(rawCreative => ({
        id: rawCreative.id,
        creativeId: rawCreative.creative_vid_id,
        endDate: rawCreative.end
          ? new Date(rawCreative.end.seconds * 1000)
          : undefined,
        startDate: rawCreative.start
          ? new Date(rawCreative.start.seconds * 1000)
          : undefined,
        startASAP: !!rawCreative.start_asap,
        weight: rawCreative.weight / 100,
        clickThroughUrl: findClickthroughFromEventsLegacy(
          rawCreative.event_urls
        ),
        attachedLineItemId: id,
        // There are instances where event_urls will come back as null from backend
        trackingPixels: rawCreative.event_urls
          ? fillInEmptyTrackingTypes(rawCreative.event_urls)
          : DEFAULT_EMPTY_TRACKING_PIXELS,
        status: rawCreative.status
      }))
    : [];

  /**
   * Add geoTargeting for an additional column in the manage campaigns table (line item level)
   */
  const geoTargetingInclude = geo_include
    ? {
        country: geo_include.country || "",
        districts: geo_include.congressional_districts || [],
        dmaCodes: geo_include.dmas || [],
        states: geo_include.states || [],
        zipCodes: geo_include.zips || []
      }
    : {
        country: "",
        districts: [],
        dmaCodes: [],
        states: [],
        zipCodes: []
      };

  const geoTargetingExclude = geo_exclude
    ? {
        country: geo_exclude.country || "",
        districts: geo_exclude.congressional_districts || [],
        dmaCodes: geo_exclude.dmas || [],
        states: geo_exclude.states || [],
        zipCodes: geo_exclude.zips || []
      }
    : {
        country: "",
        districts: [],
        dmaCodes: [],
        states: [],
        zipCodes: []
      };

  const transformedCampaignDescendant: Omit<
    ShallowLineItem,
    | "status"
    | "userCanSetLive"
    | "isUserEditable"
    | "criticalStatuses"
    | "isUserEditableIgnoreArchived"
  > = {
    isAdbookOrder: !!(
      rawDescendant.ext_metas && rawDescendant.ext_metas.adbook_campaign_id
    ),
    id,

    budget,
    bookedImpressions: booked || 0,
    appliedSegmentIds: segments || [],
    archivedCreatives: creativesTransformed.filter(
      item => item.status === RawInstructionStatus.ARCHIVED
    ),
    attachedCreatives: creativesTransformed.filter(
      item => item.status !== RawInstructionStatus.ARCHIVED
    ),
    creativesCount: creatives
      ? creatives.filter(item => item.status !== RawInstructionStatus.ARCHIVED)
          .length
      : 0,
    dayparts: dayparts || [],
    frequencies: rawDescendant.frequencies || [],
    endDate,
    startDate,
    startASAP: !!start_asap,
    name,
    parent,
    parentName: parent_name,
    product: product_id || "",
    orderManagementSystemId: oms_id,
    externalId: external_id,
    parentCampaignId: campaignId,
    campaignName,
    rawStatus: rawDescendant.status,
    lastUpdated: new Date(rawDescendant.updated),
    updatedBy: rawDescendant.updated_by,
    hasFrequencyCap: true, // Assume every line item has a frequency cap.
    version,
    mediaType: media_type,
    geoTargeting: {
      exclude: geoTargetingExclude,
      include: geoTargetingInclude
    },
    deviceCaps: {
      desktop: (rawDescendant?.device_caps?.desktop || 0) / 100,
      mobile: (rawDescendant?.device_caps?.mobile || 0) / 100,
      tv: (rawDescendant?.device_caps?.tv || 0) / 100,
      tablet: (rawDescendant?.device_caps?.tablet || 0) / 100
    },

    // Metadata fields
    ...getFormattedMetadataFields(rawDescendant.ext_metas),

    whitelistId: whitelist_id,
    iab_category_rtb_ids
  };

  // if there is more than one status, it is considered as missing detais
  const status = CampaignStatus[DerivedLineItemStatus[derived_statuses![0]]];

  const isUserEditable = isCampaignDescendentEditable(owners || [], status);

  return {
    ...transformedCampaignDescendant,
    status,
    criticalStatuses: derived_statuses!
      .map(s => CampaignStatus[DerivedLineItemStatus[s]])
      .filter(s => isActionRequiredStatus(s)),
    userCanSetLive: calculateUserShouldBeAbleToSetInstructionLive(status),
    isUserEditable,
    isUserEditableIgnoreArchived:
      status === CampaignStatus.ARCHIVED || isUserEditable,
    scenarioId: rawDescendant.scenario_id
  };
};

export default transformRawCampaignDescendant;
