var CreativeTagReinspectStatus;
(function (CreativeTagReinspectStatus) {
    CreativeTagReinspectStatus[CreativeTagReinspectStatus["NO_CHANGE"] = 1] = "NO_CHANGE";
    CreativeTagReinspectStatus[CreativeTagReinspectStatus["CHANGED"] = 2] = "CHANGED";
    CreativeTagReinspectStatus[CreativeTagReinspectStatus["INVALID"] = 3] = "INVALID";
})(CreativeTagReinspectStatus || (CreativeTagReinspectStatus = {}));
/** Fields which are valid to filter on. */
export var FilterFields;
(function (FilterFields) {
    FilterFields["ADVERTISER_ID"] = "advertiserId";
    FilterFields["ID"] = "id";
})(FilterFields || (FilterFields = {}));
export const DEFAULT_RENDITION = {
    MD5: "",
    mime: 0,
    width: 0,
    height: 0,
    bitrate: 0,
    size: 0,
    duration: 0,
    volume: 0,
    video_bitrate: 0,
    video_codec: "",
    audio_bitrate: 0,
    audio_codec: "",
    audio_sample_rate: 0,
    audio_channel: 0,
    isDisplayType: false
};
export const SENSITIVE_CATEGORY_MAP = {
    cbd: true,
    gambling: true,
    political: true,
    lda: true
};
