import { FC, ReactNode, useCallback, useMemo } from "react";
import { css } from "@emotion/react";
import { DateTime } from "luxon";
import { kitText } from "../../styles";
import { ReadonlyFormFieldProps } from "./types";
import FormField from ".";
import { styles } from "./styles";
import { formatDateFromAny } from "../Input/Date/utils";
import { Truncated } from "../Truncated";

/** ReadonlyFormField displays raw text (or fallback) of given value */
const ReadonlyFormField: FC<ReadonlyFormFieldProps> = ({
  label,
  labelSuffix,
  labelSuffixAlign,
  truncateLabel,
  description,
  hideLabel,
  value,
  fill,
  time,
  parser,
  minWidth = "none",
  fallback = "--"
}) => {
  /**
   * handles formatting a date or range of dates to readonly value
   */
  const displayDate = useCallback(() => {
    const stringValues: string[] = [];
    const dates = Array.isArray(value) ? value : [value];
    dates.forEach(value => {
      let dateTime: DateTime | undefined;
      if (value instanceof DateTime) {
        dateTime = value;
      } else if (value instanceof Date) {
        dateTime = DateTime.fromJSDate(value);
      }
      if (dateTime) {
        if (dateTime?.isValid) {
          stringValues.push(formatDateFromAny(dateTime, time));
        } else {
          stringValues.push("Invalid Date");
        }
      }
    });
    if (stringValues.length > 0) {
      if (stringValues.length === 1) {
        return stringValues[0] || fallback;
      }
      return stringValues.slice(0, 2).join(" → ");
    }
    return fallback;
  }, [value, fallback]);

  const readonlyValue = useMemo(() => {
    let parsedValue: ReactNode = fallback;
    if (parser) {
      parsedValue = parser(value) || fallback;
    } else if (Array.isArray(value)) {
      if (!value.find(val => val instanceof Date)) {
        const vals = value?.filter(
          val => typeof val !== "undefined" && val !== null
        );
        if (vals.length) {
          parsedValue = vals.join(", ");
        } else {
          parsedValue = fallback;
        }
      } else {
        parsedValue = displayDate();
      }
    } else if (value instanceof Date) {
      parsedValue = displayDate();
    } else if (Number.isFinite(value)) {
      parsedValue = value;
    } else {
      parsedValue = value || fallback;
    }

    return parsedValue;
  }, [value, parser]);

  return (
    <div
      className="kit-ReadonlyFormField"
      css={[
        css`
          min-width: ${minWidth};
        `,
        fill && styles.fill
      ]}
    >
      <FormField
        label={label}
        truncateLabel={truncateLabel}
        hideLabel={hideLabel}
        labelSuffix={labelSuffix}
        labelSuffixAlign={labelSuffixAlign}
        description={description}
        fieldset
      >
        <span
          data-testid="readonly-value"
          css={css`
            color: var(--color-secondary);
            word-break: break-word;
            ${kitText.variant.body}
          `}
        >
          {typeof readonlyValue === "string" ? (
            <Truncated text={readonlyValue} />
          ) : (
            readonlyValue || fallback
          )}
        </span>
      </FormField>
    </div>
  );
};

export default ReadonlyFormField;
