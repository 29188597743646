import {
  GeographicEntityTypes,
  GeographyTargetingDetails,
  GeographyTargets,
  LineItem
} from "@madhive/mad-sdk";
import { IndividualRegion } from "appReducers/geographyReducer";
import { ShallowLineItem } from "campaign-types";
import { GeoSelectionProps } from "components/Reusable/GeoSelection/GeoSelection";
import { ROW_ID_DELIMITER } from "./types";

/** Key name conversions for geo data. */
const geosToLineItemKeys: Record<
  GeographicEntityTypes,
  keyof GeographyTargets
> = {
  country: "country",
  state: "regions",
  dma: "dmas",
  district: "districts",
  zipCode: "postalCodes"
};

export const verifyGeoRulesForBackend = (blankLineItem: Partial<LineItem>) => {
  // If regions or postalCodes is excluded, a country is require in the exclude object.
  // Otherwise errors with: "Must include either DMA, country with postals or regions, or congressional districts"
  // We always need a country in the include object, even selecting only excluded fields.
  // Otherwise errors with: "Cannot have geo exclusion without positive geo targeting".
  const geoTargeting = blankLineItem?.geoTargeting;
  if (geoTargeting) {
    if (
      !geoTargeting.exclude.country &&
      (geoTargeting.exclude.regions?.length > 0 ||
        geoTargeting.exclude.postalCodes?.length > 0)
    ) {
      geoTargeting.exclude.country = geoTargeting.include.country;
    }
  }
};

/** Translates the geo data shape from GeographicSelection for BulkEditLineItems.
 * Using promise to complete the translation before any action.
 * @param selectedGeos data for display in GeoSelectionV2 component
 * @return Converted geo data shape for LineItem
 */
export const translateGeoToLineItem = (
  selectedGeos: GeoSelectionProps
): GeographyTargetingDetails => {
  return Object.entries(selectedGeos).reduce(
    (accumulator, [key, geographicSelection]) => {
      const operation: string =
        geographicSelection.logicalOperator || "include";
      const keyNameTranslation = geosToLineItemKeys[key];
      const geoSelectionValues = geographicSelection.values;
      let newValue: string | number[] | string[];

      if (key === "country") {
        newValue = geographicSelection.id as string;
      } else if (key === "dma") {
        newValue = geoSelectionValues?.map((value: IndividualRegion) =>
          parseInt(value.id, 10)
        ) as number[];
      } else {
        newValue = geoSelectionValues?.map(
          (value: IndividualRegion) => value.id
        ) as string[];
      }

      return {
        ...accumulator,
        [operation]: {
          ...accumulator[operation],
          [keyNameTranslation]: newValue
        }
      };
    },
    {
      include: {
        country: "",
        regions: [],
        districts: [],
        dmas: [],
        postalCodes: []
      },
      exclude: {
        country: "",
        regions: [],
        districts: [],
        dmas: [],
        postalCodes: []
      }
    } as GeographyTargetingDetails
  );
};

export const increaseBookedImpressionsBy = (percent: number, from: number) =>
  Math.round(from + (percent / 100) * from);

export const createBulkEdits = (
  blankLineItem: Partial<LineItem>,
  selectedChangeIds: Set<string>,
  lineItems: ShallowLineItem[]
) => {
  const bulkEdits: Record<string, Partial<LineItem>> = {};
  selectedChangeIds.forEach(selectedChangeId => {
    const [lineItemId, field] = selectedChangeId.split(ROW_ID_DELIMITER);
    if (!bulkEdits[lineItemId]) {
      bulkEdits[lineItemId] = {
        id: lineItemId,
        /**
         * We need to include parent id in edits so that when a firestore
         * notification is created, we can display text that shows how
         * campaigns are affected
         * */
        parent: lineItems.find(({ id }) => id === lineItemId)?.parent
      };
    }

    const value =
      field === "bookedImpressions"
        ? increaseBookedImpressionsBy(
            blankLineItem?.[field] || 0,
            lineItems.find(item => item.id === lineItemId)?.bookedImpressions ||
              0
          )
        : blankLineItem[field];

    bulkEdits[lineItemId][field] = value;
  });

  return Object.values(bulkEdits);
};

export const generateErrorCsvRows = (
  errors: string[],
  lineItems: Partial<LineItem>[]
): string[][] => {
  const rows: string[][] = [];

  lineItems.forEach(li => {
    const id = li.id;

    if (id) {
      const liErrors: string[] = [];

      errors.forEach(err => id && err.includes(id) && liErrors.push(err));

      liErrors.length && rows.push([id, liErrors.join(", ")]);
    }
  });

  return rows;
};

export const numberComparator = (a: number, b: number) => a - b;
