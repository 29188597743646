import { satoshisToDollars, dollarsToSatoshis, formatDate, dateFromFormat } from "../../utils";
import { inventorySetToService, serviceToInventorySet } from "../inventorySet";
export var ServiceDealStatus;
(function (ServiceDealStatus) {
    ServiceDealStatus[ServiceDealStatus["INVALID"] = 0] = "INVALID";
    ServiceDealStatus[ServiceDealStatus["DRAFT"] = 100] = "DRAFT";
    ServiceDealStatus[ServiceDealStatus["READY"] = 200] = "READY";
    ServiceDealStatus[ServiceDealStatus["PAUSED"] = 300] = "PAUSED";
    ServiceDealStatus[ServiceDealStatus["CANCELLED"] = 400] = "CANCELLED";
    ServiceDealStatus[ServiceDealStatus["ARCHIVED"] = 500] = "ARCHIVED";
    ServiceDealStatus[ServiceDealStatus["TEST"] = 800] = "TEST";
})(ServiceDealStatus || (ServiceDealStatus = {}));
export var DealStatus;
(function (DealStatus) {
    DealStatus["INVALID"] = "Invalid";
    DealStatus["DRAFT"] = "Draft";
    DealStatus["READY"] = "Ready";
    DealStatus["PAUSED"] = "Paused";
    DealStatus["CANCELLED"] = "Cancelled";
    DealStatus["ARCHIVED"] = "Archived";
    DealStatus["TEST"] = "Test";
})(DealStatus || (DealStatus = {}));
const serviceStatusToDealStatus = (status) => {
    switch (status) {
        case ServiceDealStatus.INVALID:
            return DealStatus.INVALID;
        case ServiceDealStatus.DRAFT:
            return DealStatus.DRAFT;
        case ServiceDealStatus.READY:
            return DealStatus.READY;
        case ServiceDealStatus.PAUSED:
            return DealStatus.PAUSED;
        case ServiceDealStatus.CANCELLED:
            return DealStatus.CANCELLED;
        case ServiceDealStatus.ARCHIVED:
            return DealStatus.ARCHIVED;
        case ServiceDealStatus.TEST:
            return DealStatus.TEST;
        default:
            return undefined;
    }
};
export const dealStatusToServiceStatus = (status) => {
    switch (status) {
        case DealStatus.INVALID:
            return ServiceDealStatus.INVALID;
        case DealStatus.DRAFT:
            return ServiceDealStatus.DRAFT;
        case DealStatus.READY:
            return ServiceDealStatus.READY;
        case DealStatus.PAUSED:
            return ServiceDealStatus.PAUSED;
        case DealStatus.CANCELLED:
            return ServiceDealStatus.CANCELLED;
        case DealStatus.ARCHIVED:
            return ServiceDealStatus.ARCHIVED;
        case DealStatus.TEST:
            return ServiceDealStatus.TEST;
        default:
            return undefined;
    }
};
export var ServiceDealPriceType;
(function (ServiceDealPriceType) {
    ServiceDealPriceType["INVALID"] = "INVALID_COST_FUNC";
    ServiceDealPriceType["FLOOR"] = "FLOOR_PRICE";
    ServiceDealPriceType["FIXED"] = "FIXED_PRICE";
    ServiceDealPriceType["PG"] = "PROGRAMMATIC_GUARANTEED";
})(ServiceDealPriceType || (ServiceDealPriceType = {}));
export var DealPriceType;
(function (DealPriceType) {
    DealPriceType["FIXED"] = "Fixed";
    DealPriceType["FLOOR"] = "Floor";
})(DealPriceType || (DealPriceType = {}));
const servicePriceTypeToDealPriceType = (cost_func) => {
    switch (cost_func) {
        case ServiceDealPriceType.PG:
        case ServiceDealPriceType.FIXED:
            return DealPriceType.FIXED;
        case ServiceDealPriceType.FLOOR:
            return DealPriceType.FLOOR;
        default:
            return undefined;
    }
};
export var ServiceDealBidRuleType;
(function (ServiceDealBidRuleType) {
    ServiceDealBidRuleType["INVALID"] = "INVALID_TYPE";
    ServiceDealBidRuleType["CEILING"] = "CEILING_PRICE";
    ServiceDealBidRuleType["OVERRIDE"] = "OVERRIDE_PRICE";
})(ServiceDealBidRuleType || (ServiceDealBidRuleType = {}));
export var DealBidRuleType;
(function (DealBidRuleType) {
    DealBidRuleType["CEILING"] = "Ceiling";
    DealBidRuleType["OVERRIDE"] = "Override";
})(DealBidRuleType || (DealBidRuleType = {}));
const serviceDealBidRuleTypeToDealBidRuleType = (bid_rule) => {
    switch (bid_rule) {
        case ServiceDealBidRuleType.CEILING:
            return DealBidRuleType.CEILING;
        case ServiceDealBidRuleType.OVERRIDE:
            return DealBidRuleType.OVERRIDE;
        default:
            return undefined;
    }
};
const dealBidRuleTypeToServiceDealBidRuleType = (bidRule, priceType) => {
    if (priceType !== DealPriceType.FLOOR) {
        return undefined;
    }
    switch (bidRule) {
        case DealBidRuleType.CEILING:
            return ServiceDealBidRuleType.CEILING;
        case DealBidRuleType.OVERRIDE:
            return ServiceDealBidRuleType.OVERRIDE;
        default:
            return undefined;
    }
};
export var ServiceDealType;
(function (ServiceDealType) {
    ServiceDealType["INVALID"] = "INVALID_DEAL_TYPE";
    ServiceDealType["PMP"] = "PMP";
    ServiceDealType["PG"] = "PG";
    ServiceDealType["SUPER_TAG"] = "SUPERTAG";
    ServiceDealType["OPEN_MARKET"] = "OPENMARKET";
})(ServiceDealType || (ServiceDealType = {}));
export var DealType;
(function (DealType) {
    DealType["PMP"] = "PMP";
    DealType["PG"] = "PG";
    DealType["SUPER_TAG"] = "SuperTag";
    DealType["OPEN_MARKET"] = "OpenMarket";
})(DealType || (DealType = {}));
const serviceDealTypeToDealType = (dealType) => {
    switch (dealType) {
        case ServiceDealType.PG:
            return DealType.PG;
        case ServiceDealType.PMP:
            return DealType.PMP;
        case ServiceDealType.SUPER_TAG:
            return DealType.SUPER_TAG;
        default:
            return DealType.OPEN_MARKET;
    }
};
const dealPriceCostFuncToServiceCostFunc = (dealType, priceType) => {
    if (dealType === DealType.PG) {
        return ServiceDealPriceType.PG;
    }
    switch (priceType) {
        case DealPriceType.FLOOR:
            return ServiceDealPriceType.FLOOR;
        case DealPriceType.FIXED:
            return ServiceDealPriceType.FIXED;
        default:
            return undefined;
    }
};
export var ServiceDealMediaType;
(function (ServiceDealMediaType) {
    ServiceDealMediaType["NONE"] = "NONE";
    ServiceDealMediaType["VIDEO"] = "VIDEO";
    ServiceDealMediaType["AUDIO"] = "AUDIO";
    ServiceDealMediaType["DISPLAY"] = "DISPLAY";
})(ServiceDealMediaType || (ServiceDealMediaType = {}));
export var DealMediaType;
(function (DealMediaType) {
    DealMediaType["VIDEO"] = "Video";
    DealMediaType["AUDIO"] = "Audio";
    DealMediaType["DISPLAY"] = "Display";
})(DealMediaType || (DealMediaType = {}));
const serviceDealMediaTypesToDealMediaTypes = (media_types) => {
    const dealMediaTypes = [];
    if (media_types?.includes(ServiceDealMediaType.VIDEO)) {
        dealMediaTypes.push(DealMediaType.VIDEO);
    }
    if (media_types?.includes(ServiceDealMediaType.AUDIO)) {
        dealMediaTypes.push(DealMediaType.AUDIO);
    }
    if (media_types?.includes(ServiceDealMediaType.DISPLAY)) {
        dealMediaTypes.push(DealMediaType.DISPLAY);
    }
    return dealMediaTypes;
};
export const dealMediaTypesToServiceDealMediaTypes = (mediaTypes) => {
    const serviceMediaTypes = [];
    if (mediaTypes?.includes(DealMediaType.VIDEO)) {
        serviceMediaTypes.push(ServiceDealMediaType.VIDEO);
    }
    if (mediaTypes?.includes(DealMediaType.AUDIO)) {
        serviceMediaTypes.push(ServiceDealMediaType.AUDIO);
    }
    if (mediaTypes?.includes(DealMediaType.DISPLAY)) {
        serviceMediaTypes.push(ServiceDealMediaType.DISPLAY);
    }
    return serviceMediaTypes;
};
export var ServiceDealVideoType;
(function (ServiceDealVideoType) {
    ServiceDealVideoType["VT_NONE"] = "VT_NONE";
    ServiceDealVideoType["OTT"] = "OTT";
    ServiceDealVideoType["OLV"] = "OLV";
})(ServiceDealVideoType || (ServiceDealVideoType = {}));
export var DealVideoType;
(function (DealVideoType) {
    DealVideoType["OTT"] = "OTT";
    DealVideoType["OLV"] = "OLV";
})(DealVideoType || (DealVideoType = {}));
const serviceDealVideoTypesToDealVideoTypes = (video_types) => {
    const dealVideoTypes = [];
    if (video_types?.includes(ServiceDealVideoType.OTT)) {
        dealVideoTypes.push(DealVideoType.OTT);
    }
    if (video_types?.includes(ServiceDealVideoType.OLV)) {
        dealVideoTypes.push(DealVideoType.OLV);
    }
    return dealVideoTypes;
};
export const dealVideoTypesToServiceDealVideoTypes = (videoType, mediaTypes) => {
    if (!mediaTypes?.includes(DealMediaType.VIDEO)) {
        return [];
    }
    const serviceVideoTypes = [];
    if (videoType?.includes(DealVideoType.OTT)) {
        serviceVideoTypes.push(ServiceDealVideoType.OTT);
    }
    if (videoType?.includes(DealVideoType.OLV)) {
        serviceVideoTypes.push(ServiceDealVideoType.OLV);
    }
    return serviceVideoTypes;
};
export var ServiceDealSource;
(function (ServiceDealSource) {
    ServiceDealSource["UNASSIGNED"] = "UNASSIGNED";
    ServiceDealSource["MADHIVE"] = "MADHIVE";
    ServiceDealSource["CLIENT"] = "CLIENT";
    ServiceDealSource["O_AND_O"] = "O_AND_O";
})(ServiceDealSource || (ServiceDealSource = {}));
export var DealSource;
(function (DealSource) {
    DealSource["MADHIVE"] = "Madhive-sourced";
    DealSource["CLIENT"] = "Client-sourced";
    DealSource["O_AND_O"] = "O&O";
})(DealSource || (DealSource = {}));
const serviceSourceToDealSource = (status) => {
    switch (status) {
        case ServiceDealSource.MADHIVE:
            return DealSource.MADHIVE;
        case ServiceDealSource.CLIENT:
            return DealSource.CLIENT;
        case ServiceDealSource.O_AND_O:
            return DealSource.O_AND_O;
        default:
            return undefined;
    }
};
export const dealSourceToServiceSource = (status) => {
    switch (status) {
        case DealSource.MADHIVE:
            return ServiceDealSource.MADHIVE;
        case DealSource.CLIENT:
            return ServiceDealSource.CLIENT;
        case DealSource.O_AND_O:
            return ServiceDealSource.O_AND_O;
        default:
            return ServiceDealSource.UNASSIGNED;
    }
};
export const dealTypeToServiceType = (dealType) => {
    switch (dealType) {
        case DealType.PG:
            return ServiceDealType.PG;
        case DealType.PMP:
            return ServiceDealType.PMP;
        case DealType.SUPER_TAG:
            return ServiceDealType.SUPER_TAG;
        case DealType.OPEN_MARKET:
            return ServiceDealType.OPEN_MARKET;
        default:
            return undefined;
    }
};
export var DealCategory;
(function (DealCategory) {
    /**
     * Default (no specified inventory_set)
     * — requires `product` & `publisherGroupId` on line item,
     * - was the only targeting type allowed, prior to 6/2023
     */
    DealCategory["PRODUCT_ONLY"] = "product-only";
    /**
     * PG Deal (Programmatic Guaranteed) Deals
     * - only `inventory_set` used
     * — no other targeting related data applied on line item
     */
    DealCategory["PG_DEAL"] = "pg-deal";
    /**
     * Non-PG deal, requires Non-PG Deal in `inventory_set`
     * — (optionally) line item can also have `product` & `publisherGroupId`
     */
    DealCategory["NON_PG_DEAL"] = "non-pg-deal";
})(DealCategory || (DealCategory = {}));
export const DealCategoryLabel = {
    [DealCategory.PRODUCT_ONLY]: "Product Only",
    [DealCategory.PG_DEAL]: "PG Deal",
    [DealCategory.NON_PG_DEAL]: "PMP Deals and SuperTags"
};
export const serviceToDeal = (base) => {
    const { id, name, status: service_status, price: { cpm, cost_func, bid_rule = {} } = {}, deal_id, content_owner: contentOwner, negotiated_volume: negotiatedVolume, geo_targeting: geoTargeting, audience_targeting: audienceTargeting, created_by: createdBy, updated_by: updatedBy, is_group: isGroup, start_date, end_date, created_at: { seconds: created } = { seconds: 0 }, updated_at: { seconds: updated } = { seconds: 0 }, media_types, video_types, notes, fallback_app: fallbackApp, always_use_fallback: alwaysUseFallback, source: serviceSource } = base;
    const mediaTypes = serviceDealMediaTypesToDealMediaTypes(media_types) || [];
    const videoTypes = serviceDealVideoTypesToDealVideoTypes(video_types) || [];
    const startDate = start_date ? dateFromFormat(start_date) : undefined;
    const endDate = end_date ? dateFromFormat(end_date) : undefined;
    const parentOrg = {
        id: base.parent_org_id,
        name: base.parent_org_name
    };
    const billingOrg = {
        id: base.billing_org_id,
        name: base.billing_org_name
    };
    const ssp = {
        code: base.ssp_code,
        name: base.ssp_name
    };
    const status = serviceStatusToDealStatus(service_status);
    const deal = {
        id: deal_id,
        type: serviceDealTypeToDealType(base.type)
    };
    const priceType = [DealType.PG, DealType.SUPER_TAG].includes(deal.type)
        ? DealPriceType.FIXED
        : servicePriceTypeToDealPriceType(cost_func);
    const price = {
        cpm: typeof cpm === "number" ? satoshisToDollars(cpm) : deal.type === DealType.PMP ? undefined : 0,
        type: priceType,
        bidRule: {
            type: serviceDealBidRuleTypeToDealBidRuleType(bid_rule.type),
            cpm: typeof bid_rule.cpm === "number" && priceType === DealPriceType.FLOOR
                ? satoshisToDollars(bid_rule.cpm)
                : undefined
        }
    };
    const { includeIds, excludeIds } = serviceToInventorySet(base.inventory_set);
    const source = serviceSourceToDealSource(serviceSource);
    return {
        id,
        name,
        ...((!!price.cpm || !!price.type) && { price }),
        ...((!!deal?.id || !!deal?.type) && { deal }),
        ...("status" in base && { status }),
        ...(("parent_org_id" in base || "parent_org_name" in base) && { parentOrg }),
        ...(("billing_org_id" in base || "billing_org_name" in base) && { billingOrg }),
        ...(("ssp_code" in base || "ssp_name" in base) && { ssp }),
        ...("content_owner" in base && { contentOwner }),
        ...("negotiated_volume" in base && { negotiatedVolume }),
        ...("geo_targeting" in base && { geoTargeting }),
        ...("audience_targeting" in base && { audienceTargeting }),
        ...("is_group" in base && { isGroup }),
        ...("media_types" in base && { mediaTypes }),
        ...("video_types" in base && { videoTypes }),
        ...("created_by" in base && { createdBy }),
        ...("updated_by" in base && { updatedBy }),
        ...(!!(includeIds || excludeIds) && { inventorySet: { includeIds, excludeIds } }),
        ...("start_date" in base && { startDate }),
        ...("end_date" in base && { endDate }),
        ...("created_at" in base && { createdAt: new Date(created * 1000) }),
        ...("updated_at" in base && { updatedAt: new Date(updated * 1000) }),
        ...("notes" in base && { notes }),
        ...("fallback_app" in base && { fallbackApp }),
        ...("always_use_fallback" in base && { alwaysUseFallback }),
        ...("source" in base && { source })
    };
};
export const dealToService = (base) => {
    const { id, name, price, contentOwner: content_owner, negotiatedVolume: negotiated_volume, geoTargeting: geo_targeting, audienceTargeting: audience_targeting, status: dealStatus, deal: { type: deal_type } = {}, startDate, endDate, mediaTypes, videoTypes, notes, source: dealSource } = base;
    const parent_org_id = base.parentOrg?.id?.trim() || "";
    const billing_org_id = base.billingOrg?.id?.trim() || "";
    const deal_id = base.deal?.id?.trim() || "";
    const ssp_code = base.ssp?.code?.trim() || "";
    const start_date = startDate instanceof Date ? formatDate(startDate) : "";
    const end_date = endDate instanceof Date ? formatDate(endDate) : "";
    let { fallbackApp: fallback_app, alwaysUseFallback: always_use_fallback } = base;
    if (deal_type !== DealType.SUPER_TAG) {
        fallback_app = undefined;
        always_use_fallback = undefined;
    }
    const media_types = dealMediaTypesToServiceDealMediaTypes(mediaTypes);
    const video_types = dealVideoTypesToServiceDealVideoTypes(videoTypes, mediaTypes);
    const status = dealStatusToServiceStatus(dealStatus);
    let cpm = typeof price?.cpm === "number" ? dollarsToSatoshis(price.cpm) : undefined;
    if (cpm === undefined && deal_type !== DealType.PMP) {
        cpm = 0;
    }
    const servicePrice = {
        cpm,
        cost_func: dealPriceCostFuncToServiceCostFunc(deal_type, price?.type)
    };
    const bid_rule = {
        type: dealBidRuleTypeToServiceDealBidRuleType(price?.bidRule?.type, price?.type),
        cpm: typeof price?.bidRule?.cpm === "number" && price?.type === DealPriceType.FLOOR
            ? dollarsToSatoshis(price.bidRule.cpm)
            : undefined
    };
    if (servicePrice.cost_func === ServiceDealPriceType.FLOOR && bid_rule.type) {
        servicePrice.bid_rule = bid_rule;
    }
    const { include_ids, exclude_ids } = inventorySetToService(base.inventorySet);
    const source = dealSourceToServiceSource(dealSource);
    const type = dealTypeToServiceType(deal_type);
    return {
        id,
        name,
        ...((servicePrice.cpm !== undefined || !!servicePrice.cost_func) && {
            price: servicePrice
        }),
        ...("contentOwner" in base && { content_owner }),
        ...("negotiatedVolume" in base && { negotiated_volume }),
        ...("geoTargeting" in base && { geo_targeting }),
        ...("audienceTargeting" in base && { audience_targeting }),
        ...("status" in base && status && { status }),
        ...(!!(include_ids || exclude_ids) && { inventory_set: { include_ids, exclude_ids } }),
        ...("mediaTypes" in base && { media_types }),
        ...("videoTypes" in base && { video_types }),
        ...("parentOrg" in base && { parent_org_id }),
        ...("billingOrg" in base && { billing_org_id }),
        ...("deal" in base && { deal_id }),
        ...("ssp" in base && { ssp_code }),
        ...("startDate" in base && { start_date }),
        ...("endDate" in base && { end_date }),
        ...("notes" in base && { notes }),
        ...("fallbackApp" in base && { fallback_app }),
        ...("alwaysUseFallback" in base && { always_use_fallback }),
        ...("source" in base && { source }),
        ...(!!deal_type && { type })
    };
};
