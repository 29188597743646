import { useState, useMemo } from "react";
import { DoNotCare } from "frontier/lib/kit/types";
import { UseDropDownReturn, UseDropdownArgs } from "../types";
import { useSearcher, useSelectedItems, useItemsMap } from "../../../hooks";

const useDropdown = <
  Item extends Record<string, DoNotCare> | string =
    | Record<string, DoNotCare>
    | string,
  Section extends Record<string, DoNotCare> | string =
    | Record<string, DoNotCare>
    | string
>({
  selected: incomingSelected,
  fixed,
  items,
  displayCap,
  cannotClear = false,
  searchable,
  search,
  isMulti,
  itemsMap: incomingItemsMap,
  sections: incomingSections,
  hideSelected,
  onSelect,
  lookupSection,
  lookupSectionTitle,
  lookupSectionId,
  lookupId
}: UseDropdownArgs<Item, Section>): UseDropDownReturn<Item> => {
  const itemsMap = useItemsMap<Item>(
    incomingItemsMap instanceof Map ? incomingItemsMap : items,
    lookupId
  );
  const selectedItems = useSelectedItems<Item>(
    itemsMap,
    lookupId,
    incomingSelected
  );

  const { filteredItems, isLoading, setQuery, query } = useSearcher({
    items,
    disabled: searchable === false || (!searchable && !search),
    keys: search?.keys || [],
    isLoading: search?.isLoading,
    debounceMs: search?.debounceMs,
    onAsyncQuery: search?.onAsyncQuery
  });

  const getCappedItems = () => {
    const itemsList = search?.onAsyncQuery ? items : filteredItems;
    if (!displayCap) {
      return itemsList;
    }
    return itemsList.slice(0, displayCap);
  };

  const [cappedItems, setCappedItems] = useState<Item[]>(getCappedItems());

  useMemo(() => {
    setCappedItems(getCappedItems());
  }, [displayCap, filteredItems, items]);

  const sections = useMemo<UseDropDownReturn<Item>["sections"]>(() => {
    if (!incomingSections?.length) {
      return [
        {
          items: cappedItems,
          title: "",
          sectionId: "",
          selected: incomingSelected,
          visibleCount:
            cappedItems.length - (hideSelected ? selectedItems.length : 0)
        }
      ];
    }
    return incomingSections.map(section => {
      const sectionId = lookupSectionId?.(section) || String(section);
      const items = cappedItems.filter(
        item => lookupSection?.(item) === sectionId
      );
      const selected = selectedItems.reduce<string[]>((acc, item) => {
        if (lookupSection?.(item) === sectionId) {
          const id = lookupId(item);
          if (id) {
            acc.push(id);
          }
        }
        return acc;
      }, []);

      let title = lookupSectionTitle?.(section);
      if (typeof title !== "string") {
        title = String(section);
      }

      return {
        items,
        selected,
        title,
        sectionId,
        visibleCount: items.length - (hideSelected ? selected.length : 0)
      };
    });
  }, [cappedItems, incomingSections, selectedItems, hideSelected]);

  const onSelectItem: UseDropDownReturn["onSelectItem"] = id => {
    let selected: string[] = [];
    if (incomingSelected.includes(id)) {
      const filtered = incomingSelected.filter(selectedId => selectedId !== id);
      if (!isMulti && cannotClear && filtered.length === 0) {
        return;
      }
      selected = filtered;
    } else {
      selected = isMulti ? [...incomingSelected, id] : [id];
    }
    onSelect(selected);
  };

  const clearable = isMulti
    ? selectedItems.length > (fixed?.size || 0)
    : !cannotClear;

  return {
    cappedItems,
    selectedCount: selectedItems.length || 0,
    clearable,
    selectedItems,
    isLoading,
    query,
    sections,
    onSelectItem,
    clearSelected: () => onSelect([]),
    setQuery
  };
};

export default useDropdown;
